import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Slide,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { login } from "../actions/userActions";
import { trackEvent } from "../analytics";
import ErrorIcon from "@mui/icons-material/Error";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import backgroundImage from "./../assets/images/bgImage.png";
import lawgptLogo from "./../assets/images/lawgptLogo.png";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [notVerifiederrorOpen, setNotVerifiedErrorOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error } = userLogin;

  const handleErrorClose = () => {
    setErrorOpen(false);
    setNotVerifiedErrorOpen(false);
  };

  const handleClose = () => {
    setOpenToast(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username.trim() === "" || password.trim() === "") {
      setToastText("Please enter your credentials");
      setOpenToast(true);
      trackEvent("Form", "Empty Credentials", "Empty Credentials");
      return;
    }
    setisLoading(true);
    dispatch(login(username, password));
    trackEvent("Form", "Login Attempt", "Login Attempt");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (userInfo?.status === "success") {
      trackEvent("Form", "Successful Login", `user_id: ${userInfo._id}`);
      setisLoading(false);
      navigate("/home");
    } else {
      if (error?.includes("User is not active")) {
        setisLoading(false);
        setErrorOpen(true);
      } else if (error?.includes("User is not verified")) {
        setisLoading(false);
        setNotVerifiedErrorOpen(true);
      } else {
        if (error) {
          setisLoading(false);
          setToastText(error);
          setOpenToast(true);
        }
      }

      trackEvent("Form", "Login Failure", `User: ${userInfo?.name}`);
      dispatch({ type: "USER_LOGIN_RESET" });
    }
  }, [userInfo, error, navigate, dispatch]);

  const handleRegisterClick = () => {
    navigate("/signup");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: { xs: "90%", sm: "400px" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* Logo */}
        <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
          <img
            src={lawgptLogo}
            alt="product logo"
            style={{
              maxWidth: "150px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            alignSelf: "flex-start",
            //ml: 1,
            fontWeight: "bold",
            color: "#003D78",
            position: "relative",
            mb: 2,
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 0,
              width: "23px",
              height: "3px",
              backgroundColor: "#2CC4EF",
            },
          }}
        >
          Login
        </Typography>
        <TextField
          size="small"
          fullWidth
          label="Email"
          variant="standard"
          margin="normal"
          InputLabelProps={{
            sx: {
              color: "#003D78",
              "&.Mui-focused": {
                color: "primary.main",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlinedIcon sx={{ color: "#003D78" }} />
              </InputAdornment>
            ),
            sx: {
              padding: "2px",
              "&:before": {
                borderBottomColor: "#003D78",
              },
              "&:after": {
                borderBottomColor: "primary.main",
              },
              "& input:-webkit-autofill": {
                backgroundColor: "transparent",
                WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                WebkitTextFillColor: "#003D78",
              },
            },
          }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <TextField
          size="small"
          fullWidth
          label="Password"
          variant="standard"
          margin="normal"
          type={showPassword ? "text" : "password"}
          InputLabelProps={{
            sx: {
              color: "#003D78",
              "&.Mui-focused": {
                color: "primary.main",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ color: "#003D78" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  sx={{ color: "#003D78" }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              padding: "2px",
              "&:before": {
                borderBottomColor: "#003D78",
              },
              "&:after": {
                borderBottomColor: "primary.main",
              },
              "& input:-webkit-autofill": {
                backgroundColor: "transparent",
                WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                WebkitTextFillColor: "#003D78",
              },
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        {/* Forgot Password */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mb: 1,
          }}
          onClick={() => navigate("/forgot-password")}
        >
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer", color: "#003D78" }}
          >
            Forgot Password?
          </Typography>
        </Box>
        <Button
          size="large"
          fullWidth
          sx={{
            display: "flex",
            fontWeight: "bold",
            borderRadius: "8px",
            backgroundColor: "#003D78",
            marginTop: "0.5rem",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress size={22} sx={{ color: "#fff" }} />
          ) : (
            "Login"
          )}
        </Button>
        {/* Register */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 1.5,
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Don't have an account?{" "}
            <Typography
              component="span"
              variant="body2"
              sx={{ cursor: "pointer", color: "#003D78", fontWeight: "bold" }}
              onClick={handleRegisterClick}
            >
              Register now!
            </Typography>
          </Typography>
        </Box>
      </Box>

      {(error || openToast) && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openToast}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            sx={{ width: "100%" }}
            severity="error"
            variant="filled"
            color="error"
            onClose={handleClose}
          >
            {toastText}
          </Alert>
        </Snackbar>
      )}
      {/* Error Popup */}
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "20px",
            width: { xs: "90%", sm: "400px" },
            height: "auto",
            maxWidth: "100%",
          },
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up", timeout: 500 }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <ErrorIcon sx={{ color: "#dc3545", fontSize: "50px" }} />
          <DialogTitle
            sx={{
              color: "#003D78",
              fontWeight: "bold",
              fontSize: "20px",
              padding: "12px 0",
            }}
          >
            Account Inactive
          </DialogTitle>
          <Typography
            variant="body1"
            gutterBottom
            textAlign="center"
            sx={{
              fontSize: "16px",
              marginTop: "8px",
              color: "#333",
              padding: "0 16px",
            }}
          >
            This account is inactive. Our team will notify you via email once
            your account has been activated.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", padding: "16px 0" }}
        >
          <Button
            onClick={handleErrorClose}
            variant="contained"
            sx={{
              bgcolor: "#003D78",
              textTransform: "none",
              padding: "10px 24px",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#002F60",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/*Not Verified Error Popup */}
      <Dialog
        open={notVerifiederrorOpen}
        onClose={handleErrorClose}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "20px",
            width: { xs: "90%", sm: "400px" },
            height: "auto",
            maxWidth: "100%",
          },
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up", timeout: 500 }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <ErrorIcon sx={{ color: "#dc3545", fontSize: "50px" }} />
          <DialogTitle
            sx={{
              color: "#003D78",
              fontWeight: "bold",
              fontSize: "20px",
              padding: "12px 0",
            }}
          >
            Account Not Verified
          </DialogTitle>
          <Typography
            variant="body1"
            gutterBottom
            textAlign="center"
            sx={{
              fontSize: "16px",
              marginTop: "8px",
              color: "#333",
              padding: "0 16px",
            }}
          >
            This account is not verified. Please verify your email.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", padding: "16px 0" }}
        >
          <Button
            onClick={handleErrorClose}
            variant="contained"
            sx={{
              bgcolor: "#003D78",
              textTransform: "none",
              padding: "10px 24px",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#002F60",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LoginPage;
